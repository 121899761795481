<template>
  <div class="pointIncrease">
    <div class="top_box" @click="selectAssignee()">
      <p>
        积分受让人：<span>{{ this.men }}</span>
      </p>
      <img src="./img/selectAssignee.png" alt="" />
    </div>
    <div class="center_box">
      <div class="point_title"><span>转赠积分</span></div>
      <div class="input_box">
        <input
          v-model="bonusPoints"
          onkeyup="this.value=this.value.replace(/\D/g,'')"
          onafterpaste="this.value=this.value.replace(/\D/g,'')"
          class="points"
          v-on:input="change()"
          type="number"
        />
      </div>
      <div class="now_point">当前积分:{{ now_point }}</div>
    </div>
    <div class="record">
      <p @click="goRecord()">转赠记录</p>
    </div>
    <div class="confirm" @click="confirm()">确认</div>
    <van-popup v-model="show" position="bottom" :style="{ height: '70%' }">
      <div class="popup_box">
        <div class="top_box">
          <div class="cancel" @click="cancel()">取消</div>
          <div class="complete" @click="complete()">完成</div>
        </div>
        <div class="input_box">
          <input
            type="text"
            onkeyup="this.value=this.value.replace(/[, ]/g,'')"
            v-model="changeUserData"
            v-on:input="changeUser()"
            placeholder="搜索受让人昵称/手机号"
          />
        </div>
        <div
          class="bottom_box"
          @scroll.passive="getScroll($event)"
          ref="scollElement"
        >
          <div
            class="item"
            v-for="(item, index) in list"
            :key="index"
            @click="selectUser(item)"
          >
            <div class="pic">
              <img
                v-if="item.avatar == ''"
                src="@/assets/img/default_avatar.png"
                alt=""
              />
              <img v-else :src="item.avatar" alt="" />
            </div>
            <div class="name">
              {{ item.nickName }}<span>{{ item.mobile }}</span>
            </div>
            <div class="points">{{ item.availablePoint }}</div>
          </div>
          <div class="no_more" v-if="no_more == true || list.length == 0">
            没有更多了
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Toast } from "vant";
import {
  getUserList,
  pointsUrl,
  donatedPoints,
  getPointRecordList,
} from "./api.js";
export default {
  name: "pointIncrease",
  data() {
    return {
      no_more: false,
      finishedText: "没有更多了",
      timer: null,
      changeUserData: "", //搜索受让人信息
      list: [], //用户列表
      show: false,
      men: "请选择受让人",
      now_point: 0,
      bonusPoints: null, // 转赠积分
      curPage: 1, //默认页码
      pageSize: 10, //默认页数
      doneeUserId: "", //受赠者用户ID
      doneeTenant: "", //受赠者租户ID
      total: 0, //总人数
    };
  },
  computed: {
    userId() {
      //当前用户ID
      return this.$store.state.userId;
    },
    tenantId() {
      //当前租户ID
      return this.$store.state.tenantId;
    },
  },
  created() {},
  mounted() {
    console.log(isNaN(""));
    this.userList();
    if (this.userId) {
      this.getPoints();
    }
  },
  methods: {
    //手机号脱敏加密
    encryptionPhoneNumber(number) {
      let str = number.slice(0, 3) + "****" + number.slice(number.length - 4);
      return str;
    },
    // 完成按钮
    complete() {
      if (this.changeUserData != "" && this.list.length === 1) {
        this.men = this.list[0].nickName;
        this.show = false;
      } else {
        Toast("请选择受让人！");
      }
    },
    // 取消按钮
    cancel() {
      this.show = false;
      this.men = "请选择受让人";
      this.changeUserData = "";
      this.list = [];
      this.no_more = true;
    },
    // 判断滚动条是否滚动到底部
    getScroll(event) {
      let scrollBottom =
        event.target.scrollHeight -
        event.target.scrollTop -
        event.target.clientHeight;
      // console.log(Number(scrollBottom.toFixed(1)));
      // console.log(this.list.length, this.total);
      // if (Number(scrollBottom.toFixed(1)) < 1 && this.list.length < this.total && this.changeUserData == "") {
      //     this.list = []
      //     this.no_more = true;
      // } else
      if (
        Number(scrollBottom.toFixed(1)) < 1 &&
        this.list.length < this.total &&
        isNaN(this.changeUserData) == true
      ) {
        // 判断滚动到底部时
        if (this.curPage == 1) {
          this.curPage = 2;
        }
        let params = {
          nickName: this.changeUserData,
          mobileComplete: "",
          curPage: this.curPage++,
          pageSize: this.pageSize,
          excludeUserId: this.userId,
          mobileNeedEncrypt: 1,
        };
        this.$axios
          .get(`${getUserList}`, {
            params: params,
          })
          .then((res) => {
            if (res.code === 200) {
              let list1 = res.data.records;
              list1.forEach((item) => {
                this.list.push(item);
              });
              console.log(this.list, this.list.length);
              if (this.list.length == res.data.total) {
                this.no_more = true;
              }
            }
          });
      } else if (
        Number(scrollBottom.toFixed(1)) < 1 &&
        this.list.length < this.total &&
        isNaN(this.changeUserData) == false
      ) {
        // 判断滚动到底部时
        if (this.curPage == 1) {
          this.curPage = 2;
        }
        let params = {
          nickName: "",
          mobileComplete: this.changeUserData,
          curPage: this.curPage++,
          pageSize: this.pageSize,
          excludeUserId: this.userId,
          mobileNeedEncrypt: 1,
        };
        this.$axios
          .get(`${getUserList}`, {
            params: params,
          })
          .then((res) => {
            if (res.code === 200) {
              let list1 = res.data.records;
              list1.forEach((item) => {
                this.list.push(item);
              });
              console.log(this.list, this.list.length);
              if (this.list.length == res.data.total) {
                this.no_more = true;
              }
            }
          });
      }
    },
    // 搜索受让人input事件
    changeUser() {
      console.log(this.changeUserData);
      if (
        this.changeUserData.trim() != "" &&
        isNaN(this.changeUserData.trim()) == true
      ) {
        clearTimeout(this.timer);
        this.curPage = 1;
        this.timer = setTimeout(() => {
          let params = {
            nickName: this.changeUserData,
            mobileComplete: "",
            curPage: this.curPage,
            pageSize: this.pageSize,
            excludeUserId: this.userId,
            mobileNeedEncrypt: 1,
          };
          this.$axios
            .get(`${getUserList}`, {
              params: params,
            })
            .then((res) => {
              if (res.code === 200) {
                this.list = res.data.records;
                if (this.list.length == res.data.total) {
                  this.no_more = true;
                }
              }
            });
        }, 500);
      } else if (
        this.changeUserData.trim() != "" &&
        isNaN(this.changeUserData.trim()) == false &&
        this.changeUserData.trim().length == 11
      ) {
        this.curPage = 1;
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          let params = {
            nickName: "",
            mobileComplete: this.changeUserData,
            curPage: this.curPage,
            pageSize: this.pageSize,
            excludeUserId: this.userId,
            mobileNeedEncrypt: 1,
          };
          this.$axios
            .get(`${getUserList}`, {
              params: params,
            })
            .then((res) => {
              if (res.code === 200) {
                this.list = res.data.records;
                if (this.list.length == res.data.total) {
                  this.no_more = true;
                }
              }
            });
        }, 500);
      } else {
        this.curPage = 1;
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          let params = {
            nickName: "",
            mobileComplete: 1,
            curPage: this.curPage,
            pageSize: this.pageSize,
            excludeUserId: this.userId,
            mobileNeedEncrypt: 1,
          };
          this.$axios
            .get(`${getUserList}`, {
              params: params,
            })
            .then((res) => {
              if (res.code === 200) {
                this.list = res.data.records;
                if (this.list.length == res.data.total) {
                  this.no_more = true;
                }
              }
            });
        }, 500);
      }
    },
    // 获取当前积分
    getPoints() {
      let params = {
        userId: this.userId,
        tenantId: this.tenantId,
      };
      this.$axios
        .post(`${pointsUrl}`, this.$qs.stringify(params), {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then((res) => {
          if (res.code === 200) {
            if (res.data) {
              this.now_point = res.data.availablePoint;
            }
          } else {
          }
        });
    },
    // 获取受让人列表数据
    userList() {
      let params = {
        nickName: "",
        mobileComplete: "",
        curPage: this.curPage,
        pageSize: this.pageSize,
        excludeUserId: this.userId,
        mobileNeedEncrypt: 1,
      };
      this.$axios
        .get(`${getUserList}`, {
          params: params,
        })
        .then((res) => {
          // console.log(res);
          if (res.code === 200) {
            this.total = res.data.total;
            // this.list = res.data.records;
          }
        });
    },
    // 跳转装增记录列表页面
    goRecord() {
      this.$router.push({
        name: "transferRecord",
      });
    },
    // 确认选择受让人事件
    selectUser(item) {
      (this.men = item.nickName), (this.doneeUserId = item.userId);
      this.doneeTenant = item.tenantId;
      this.show = false;
    },
    // 选择受让人事件
    selectAssignee() {
      this.show = true;
    },
    // 转赠积分输入框input事件
    change() {
      if (Number(this.bonusPoints) > Number(this.now_point)) {
        Toast("转赠积分不能大于当前积分！");
        this.bonusPoints = null;
      }
    },
    // 确认按钮
    confirm() {
      if (this.bonusPoints == "" || this.bonusPoints == null) {
        Toast("转赠积分不能为空！");
      } else if (this.men == "请选择受让人") {
        Toast("请选择受让人！");
      } else {
        let params = {
          donorUserId: this.userId,
          donorTenant: this.tenantId,
          doneeUserId: this.doneeUserId,
          doneeTenant: this.doneeTenant,
          pointCount: this.bonusPoints,
        };
        this.$axios
          .post(`${donatedPoints}`, this.$qs.stringify(params), {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          })
          .then((res) => {
            if (res.code === 200) {
              this.bonusPoints = null;
              this.getPoints();
              this.userList();
              setTimeout(() => {
                Toast("转赠积分成功！");
              }, 0);
            }
          });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.pointIncrease {
  width: 100%;
  height: 100%;
  background: #f5f5f5;

  .popup_box {
    width: 100%;
    height: 100%;

    .bottom_box {
      width: 100%;
      height: calc(100% - 236px);
      overflow: hidden;
      overflow-y: auto;

      // padding-bottom: 100px;
      // box-sizing: border-box;
      .no_more {
        width: 100%;
        height: 100px;
        line-height: 100px;
        text-align: center;
        font-size: 28px;
        color: #a8a9ab;
        background-color: #f7f7f7;
      }

      .item {
        width: 100%;
        height: 132px;
        border-top: 1px solid #f0f0f0;
        position: relative;

        .pic {
          width: 80px;
          height: 80px;
          border-radius: 50%;
          overflow: hidden;
          position: absolute;
          top: 50%;
          left: 32px;
          transform: translateY(-50%);

          img {
            width: 100%;
            height: 100%;
          }
        }

        .name {
          color: #333333;
          font-size: 32px;
          position: absolute;
          top: 50%;
          left: 142px;
          transform: translateY(-50%);

          span {
            margin-left: 20px;
            color: rgba(0, 0, 0, 0.5);
          }
        }

        .points {
          color: #000000;
          font-size: 36px;
          position: absolute;
          top: 50%;
          right: 32px;
          transform: translateY(-50%);
        }
      }
    }

    .input_box {
      width: 686px;
      height: 82px;
      border-radius: 8px;
      margin: 0 auto;
      margin-bottom: 32px;
      overflow: hidden;

      input {
        width: 100%;
        height: 100%;
        background: #f5f5f5;
        font-size: 32px;
        color: #ccc;
        text-indent: 0.5em;
      }

      input::-webkit-input-placeholder {
        color: #cccccc;
      }
    }

    .top_box {
      width: 100%;
      height: 122px;
      box-sizing: border-box;
      position: relative;

      .cancel {
        position: absolute;
        top: 50%;
        left: 32px;
        transform: translateY(-50%);
        font-size: 36px;
        color: #333333;
      }

      .complete {
        position: absolute;
        top: 50%;
        right: 32px;
        transform: translateY(-50%);
        font-size: 36px;
        color: #007eff;
      }
    }
  }

  .confirm {
    position: absolute;
    bottom: 100px;
    left: 50%;
    transform: translateX(-50%);
    width: 686px;
    height: 96px;
    background: #007eff;
    border-radius: 48px;
    text-align: center;
    line-height: 96px;
    font-size: 32px;
    color: #ffffff;
    font-weight: 600;
  }

  .center_box {
    width: 710px;
    height: 314px;
    background: #ffffff;
    border-radius: 16px;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 0 46px;

    .input_box {
      height: 126px;
      width: 618px;
      border-bottom: 1px solid #dbdbdb;
      font-size: 90px;

      .points {
        font-weight: 600;
        width: 100%;
        height: 100%;
      }

      input.points {
        caret-color: #007eff;
      }
    }

    .now_point {
      height: 102px;
      width: 100%;
      line-height: 102px;
      font-size: 28px;
      color: rgba(0, 0, 0, 0.5);
    }

    .point_title {
      position: relative;
      height: calc(100% - 228px);
      font-size: 28px;
      color: #333333;

      span {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
      }
    }
  }

  .record {
    width: 710px;
    margin: 0 auto;
    margin-top: 28px;

    p {
      font-size: 32px;
      color: #000000;
      text-align: right;
    }
  }

  .top_box {
    height: 178px;
    line-height: 178px;
    padding: 0 68px;
    position: relative;

    img {
      width: 48px;
      height: 48px;
      position: absolute;
      top: 50%;
      right: 68px;
      transform: translateY(-50%);
    }

    p {
      font-size: 32px;
      color: #333333;
      font-weight: 400;

      span {
        color: #000000;
        font-weight: 600;
      }
    }
  }
}
</style>
